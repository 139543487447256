<script>
import FilteringControllerMixin from '@/mixins/FilteringControllerMixin';

import { TICKETS_MODULE } from '~/features/tickets/constants';
import ColorUtilities from '@/utilities/ColorUtilities';
import NumberMixin from '~/mixins/NumberMixin';

import FilterButton from '@/components/molecules/FilterButton';
import FilteringMenu from '@/components/molecules/FilterButton/FilteringMenu';
import SortButton from '@/components/molecules/SortButton';

export default defineNuxtComponent({
  components: { SortButton, FilterButton, FilteringMenu },
  mixins: [FilteringControllerMixin, NumberMixin],
  props: {
    ticketStatus: {
      type: Object,
      required: true,
      default: () => {},
    },
    ticketsNumber: {
      type: Number,
      required: true,
      default: 0,
    },
    sortCriteria: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      TICKETS_MODULE,
    };
  },
  computed: {
    errorColor() {
      return ColorUtilities.ERROR;
    },
  },
  methods: {
    onClearFilters() {
      this.$emit('onClearFilters');
    },
    filteringChanged() {
      // This is used to force the header to update after filtering from other components is changed
      this.$forceUpdate();
    },
    onSortCriteriaChanged(sortParameter, selectedCriteria) {
      this.$emit('onSortCriteriaChanged', sortParameter, selectedCriteria);
    },
  },
});
</script>

<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <div class="text-4xl font-light">
        {{ ticketStatus.description }}
      </div>
      <div
        class="ml-4 flex h-12 w-12 items-center justify-center rounded-full bg-white text-xs text-neutral-1"
      >
        {{ abbreviate(ticketsNumber, 1) }}
      </div>
      <div class="ml-auto flex space-x-4">
        <SortButton
          :sort-criteria="sortCriteria"
          @sort-criteria-changed="onSortCriteriaChanged"
        />
        <trailblazer-button
          v-if="hasAvailableFilters(TICKETS_MODULE)"
          danger
          size="m"
          icon="close"
          class="flex bg-white hover:shadow-md"
          @click="onClearFilters"
        >
          {{ __('Clear filters') }}
        </trailblazer-button>
        <trailblazer-button
          icon="filter"
          class="flex rounded bg-white"
          size="m"
          type="button"
          variant="secondary"
          @click="toggleFilteringMenu"
        >
          {{ __('Filter') }}
        </trailblazer-button>
      </div>
    </div>

    <div v-if="isFilteringMenuOpened" class="mt-3">
      <FilteringMenu
        module="tickets"
        class="rounded p-6"
        @filtering-changed="filteringChanged"
      />
    </div>
  </div>
</template>
