<script>
import FilteringControllerMixin from '@/mixins/FilteringControllerMixin';

import Button from '@/components/atoms/Button';

export default defineNuxtComponent({
  name: 'FilterButton',
  components: { Button },
  mixins: [FilteringControllerMixin],
});
</script>

<template>
  <div
    class="flex rounded border-2 border-transparent"
    :class="{ 'border-primary bg-neutral-4-600': isFilteringMenuOpened }"
  >
    <Button
      button-type="secondary"
      :label="__('Filter')"
      icon="filter"
      class="rounded bg-white"
      @click="toggleFilteringMenu"
    />
  </div>
</template>
